@import "./bootstrap_vars";
@import "./bootstrap_vars_admin";
// @import "../bootstrap/bootstrap";
@import "~bootstrap/scss/bootstrap";

@import "./admin_dashboard";
@import "./user_dashboard";
@import "./login";
@import "./Modal";
@import "./profile";
@import "./dynamic_columns";
@import "./smart_table";
@import "./global";

@import "./temp_bootstrap_overflow_fix";

@import './user_side_nav';

.navbar-space {
  margin-top: 80px;
}

.exp-drop {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.text-blue {
  color: $hornet-blue;
}

.uppercase {
  text-transform: uppercase;
}

.message {
  background: #fff;
  padding: 10px;
  margin: 10px 0 20px;
  border: 2px solid $hornet-orange;
  border-radius: 5px;
  background: lighten($hornet-orange, 30%);
  color: darken($hornet-orange, 20%);
}

.card-tabbed {
  border-top: 0;
  border-top-right-radius: 0;
  padding-top: 16px;
}

// deprecated for onboard-question-card
.how-much-invest {
  max-width: 190px;
  text-align: right;
}

.onboard-question-card {
  display: flex;
  flex-direction: column;

  .onboard-question-group {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .onboard-question-group-title {
    padding-bottom: 20px;
    color: $hornet-blue;
    text-transform: uppercase;
    font-weight: bold;
  }

  dl {
    display: flex;
    margin: 0;
    padding: 0;
    min-height: 46px;
    flex-direction: column;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      flex-direction: row;
      margin: 0;
    }

    > dt {
      display: flex;
      max-width: 100%;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      padding: 0 15px;
      @media (min-width: 768px) {
        max-width: 190px;
        width: auto;
        flex-basis: 0;
        flex-grow: 1;
        justify-content: flex-end;
      }
    }

    > dd {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      max-width: 100%;
      padding: 0 15px;

      > div {
        display: flex;
        flex: 1;
      }
    }
  }
}

.new-investment-left-form {
  max-width: 190px;
  text-align: right;
  padding-top: 7px;
}

.new-investment-right-selects {
  padding-top: 7px;
  padding-bottom: 7px;
}

@media (max-width: 768px) {
  .new-investment-left-form {
    max-width: 100%;
    width: 100%;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .no-marg-bottom {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .how-much-invest {
    max-width: 100%;
    width: 100%;
    text-align: left;
  }
}

.scroll {
  max-height: 400px;
  overflow-y: auto;
  word-wrap: break-word;
}

.form-control.fixpadding {
  padding: 0;
  border: none;
}

button.nav-link {
  background-color: transparent;
  border: none;
  font-weight: 600;

  &:focus {
    outline: none;
  }
}

.Select {
  [class$="singleValue"], input {
    color: $gray-700 !important;
  }
}

.noTransition {
  transition: none !important;
}

.profile-hr {
  border: 1px solid #007BC0;
}

.profile-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .profile-row {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }
}


.formik-input {
  margin-left: 20px;

  .input-icons {
    position: relative;
    flex-grow: 3;
    width: 30%;
    min-width: 235px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
  }

  select {
    flex-grow: 3;
    width: 100%;
    height: 46px;
    min-width: 235px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #007BC0;
    text-align: center;
  }

  select:focus {
    border: 1px solid #007BC0;
    border-radius: 10px;
  }

  .input-icons i {
    position: absolute;
  }

  .icon {
    padding: 10px;
    min-width: 40px;
    left: 85%;
    top: 10%;
  }

  .icon:hover {
    cursor: pointer;
  }

  .input-field {
    width: 100%;
    padding: 10px;
    text-align: center;
    margin-bottom: 3px;
    border-radius: 10px;
    border: 1px solid #007BC0;
  }

  .input-field:focus {
    border: 1px solid #007BC0;
    border-radius: 10px;
  }

  input:disabled {
    background: #353f49;
  }

  select:disabled {
    background: #353f49;
    opacity: 1;
  }

  .error-input-field {
    width: 100%;
    padding: 10px;
    text-align: center;
    margin-bottom: 3px;
    border: 1px solid red;
    border-radius: 10px;
  }

  .error-select {
    flex-grow: 3;
    width: 100%;
    height: 46px;
    min-width: 235px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid red;
    text-align: center;
  }

  .error-text {
    color: red;
    font-size: .75rem;
  }

  input[type="date"] {
    position: relative;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    color: transparent;
    background: none;
    z-index: 1;
    cursor: pointer;
    min-width: 35px;
    min-height: 35px;
    left: 82%;
    top: 6%;
  }

  /* So that the date input placeholder looks like the rest of the form */
  input[type="date"]::-webkit-datetime-edit-fields-wrapper {
    color: #818181;
  }


  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #007BC0FF;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #007BC0FF;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

}

.text-hornet {
  color: $hornet-dark-blue;
}

.text-alert {
  color: $hornet-red-alert;
}

.hornet-text-warning {
  color: $hornet-orange
}

.text-transformation-none {
  text-transform: none;
}

.ql-toolbar {
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}
.ql-container {
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.ql-editor:focus {
  color: #495057;
  background-color: #fff;
  border: .5px solid #41bbff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 192, 0.25);
}

.input-disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.modal-dialog {
  box-shadow: -17px 17px 52px -14px #2c2c2c
}

/* Chrome, Safari, Edge, Opera */
input.no-indicator::-webkit-outer-spin-button,
input.no-indicator::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number].no-indicator {
  -moz-appearance: textfield;
}